import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { AbsPipe } from '@shared/pipe/abs/abs.pipe';
import { CallbackPipe } from '@shared/pipe/callback/callback.pipe';
import { CreditCardPipe } from '@shared/pipe/credit-card/credit-card.pipe';
import { CustomCurrencyPipe } from '@shared/pipe/custom-currency/custom-currency.pipe';
import { DecamelizePipe } from '@shared/pipe/decamelize/decamelize.pipe';
import { FinancialYearPipe } from '@shared/pipe/financial-year/financial-year.pipe';
import { NAPipe } from '@shared/pipe/na/na.pipe';
import { ShortNumberPipe } from '@shared/pipe/short-number/short-number.pipe';
import { FileSizeConvertPipe } from '@shared/pipe/size-convert/file-size-convert.pipe';
import { SlicePipe } from '@shared/pipe/slice/slice.pipe';
import { EKeyValuePipe } from '@shared/pipe/e-key-value/e-key-value.pipe';
import { CustomDatePipe } from '@shared/pipe/custom-date/custom-date.pipe';
import { DictionaryAsyncPipe } from './dictionary-async/dictionary-async.pipe';
import { ObservablePipe } from './observable/observable.pipe';
import { PlaceholderPipe } from './placeholder/placeholder.pipe';
import { SanitizePipe } from './sanitize/sanitize.pipe';
import { BackgroundImageUrlPipe } from './background-image-url/background-image-url.pipe';
import { SortByPipe } from './sort/sort-by.pipe';
import { CallPipe } from './call/call.pipe';
import { MinDigitsPipe } from './min-digits/min-digits.pipe';
import { EValuesPipe } from './e-values/e-values.pipe';
import { AppCurrencyPipe, SafeUrlPipe, AppPercentPipe } from 'taxtank-core';
import { PercentagePipe } from 'ngx-pipes';

/**
 * Module with pipes
 */
@NgModule({
  declarations: [
    AbsPipe,
    CallbackPipe,
    CreditCardPipe,
    CustomCurrencyPipe,
    DecamelizePipe,
    FinancialYearPipe,
    NAPipe,
    ShortNumberPipe,
    FileSizeConvertPipe,
    SlicePipe,
    EKeyValuePipe,
    CustomDatePipe,
    DictionaryAsyncPipe,
    ObservablePipe,
    PlaceholderPipe,
    SanitizePipe,
    BackgroundImageUrlPipe,
    SortByPipe,
    CallPipe,
    MinDigitsPipe,
    EValuesPipe
  ],
  imports: [
    CommonModule,
    AppCurrencyPipe,
    SafeUrlPipe,
  ],
  exports: [
    AbsPipe,
    CallbackPipe,
    CreditCardPipe,
    CustomCurrencyPipe,
    DecamelizePipe,
    FinancialYearPipe,
    NAPipe,
    ShortNumberPipe,
    FileSizeConvertPipe,
    SlicePipe,
    EKeyValuePipe,
    CustomDatePipe,
    CurrencyPipe,
    AppCurrencyPipe,
    SafeUrlPipe,
    DatePipe,
    DecimalPipe,
    DictionaryAsyncPipe,
    ObservablePipe,
    PlaceholderPipe,
    SanitizePipe,
    BackgroundImageUrlPipe,
    SortByPipe,
    CallPipe,
    MinDigitsPipe,
    EValuesPipe,
    PercentPipe
  ],
  providers: [
    AbsPipe,
    CallbackPipe,
    CreditCardPipe,
    CustomCurrencyPipe,
    DecamelizePipe,
    FinancialYearPipe,
    NAPipe,
    ShortNumberPipe,
    FileSizeConvertPipe,
    SlicePipe,
    EKeyValuePipe,
    CustomDatePipe,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    DictionaryAsyncPipe,
    ObservablePipe,
    PlaceholderPipe,
    SanitizePipe,
    BackgroundImageUrlPipe,
    SortByPipe,
    CallPipe,
    // @TODO refactor and remove
    PercentPipe,
    PercentagePipe,
    AppPercentPipe
  ]
})
export class PipeModule { }
